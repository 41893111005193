/**
 * 
 * All JavaScript is written by Nathaniel A Mayers 2024. All rights reserved.
 * Do Not use without permission beforehand.
 * 
 * Script Version: 1.1.8
 */
/* jshint esversion: 6 */

/**
 * Adds an event listener with compatibility for old browsers.
 * @param {HTMLElement} element - The element to attach the event listener to.
 * @param {string} event - The event type (e.g., 'click', 'mouseover').
 * @param {Function} handler - The event handler function.
 */
function addEventListenerCompat(element, event, handler) {
    "use strict";
    if (element.addEventListener) {
        element.addEventListener(event, handler);
    } else if (element.attachEvent) {
        element.attachEvent('on' + event, handler);
    } else {
        console.error('Event handling is not supported on this browser.');
    }
}

/**
 * Removes an event listener with compatibility for old browsers.
 * @param {HTMLElement} element - The element to remove the event listener from.
 * @param {string} event - The event type (e.g., 'click', 'mouseover').
 * @param {Function} handler - The event handler function.
 */
function removeEventListenerCompat(element, event, handler) {
    "use strict";
    if (element.removeEventListener) {
        element.removeEventListener(event, handler);
    } else if (element.detachEvent) {
        element.detachEvent('on' + event, handler);
    } else {
        console.error('Event handling is not supported on this browser.');
    }
}


// intialise popup class
/**
 * Manages modal popups in a web application.
 * 
 * - Initializes with selectors for trigger buttons, overlay, popup, and close button.
 * - Opens the popup and injects content when a trigger button is clicked.
 * - Closes the popup when the close button or overlay is clicked.
 */

class Popup {
    constructor() {
        // Check if a popup already exists
        this.popupElement = document.querySelector('.popup');
        
        if (!this.popupElement) {
            // Create the popup HTML structure
            this.popupElement = document.createElement('aside');
            this.popupElement.classList.add('popup', 'hidden');
    
            const popupContentElement = document.createElement('aside');
            popupContentElement.classList.add('popup-content');
    
            this.closeButton = document.createElement('button');
            this.closeButton.classList.add('popup-close');
            this.closeButton.innerHTML = '&times;';
            
            this.messageElement = document.createElement('p');
            this.messageElement.classList.add('popup-message');
            
            // Create the extra content container
            this.extraContentElement = document.createElement('div');
            this.extraContentElement.classList.add('popup-extra-content');

            // Append elements
            popupContentElement.appendChild(this.closeButton);
            popupContentElement.appendChild(this.messageElement);
            popupContentElement.appendChild(this.extraContentElement);
            this.popupElement.appendChild(popupContentElement);
    
            document.body.appendChild(this.popupElement);

            this.addEventListeners();
        } else {
            // If popup already exists, get references to its elements
            this.closeButton = this.popupElement.querySelector('.popup-close');
            this.messageElement = this.popupElement.querySelector('.popup-message');
            this.extraContentElement = this.popupElement.querySelector('.popup-extra-content');
        }
    }

    addEventListeners() {
        this.closeButton.addEventListener('click', () => this.close());
        window.addEventListener('click', (event) => {
            if (event.target === this.popupElement) {
                this.close();
            }
        });
    }

    show(message) {
        if (this.messageElement) {
            this.messageElement.textContent = message;
        } else {
            console.error('Message element not found');
        }

        this.popupElement.classList.add('display-popup');
        this.popupElement.classList.remove('hidden');
    }
    
    close() {
        this.popupElement.classList.remove('display-popup');
        this.popupElement.classList.add('hidden');
    }

    addSection(tagName, content) {
        if (typeof tagName !== 'string' || !content) {
            console.error('Invalid arguments: expected tagName as a string and content as a non-empty string or HTMLElement');
            return;
        }

        const sectionElement = document.createElement(tagName);
        if (typeof content === 'string') {
            sectionElement.innerHTML = content;
        } else if (content instanceof HTMLElement) {
            sectionElement.appendChild(content);
        } else {
            console.error('Content must be a string or an HTMLElement');
            return;
        }

        this.extraContentElement.appendChild(sectionElement);
    }
}


// end of popup class

// intialise forms validation class
/**
 * Manages form validation in web application forms.
 * - Checks for correct address inputs.
 * - Emails and Phone number formats
 * - Null checks and empty values.
 * - Profanity word filter.
 * - Tooltips and error warnings.
 */
class FormValidator {
    constructor(form) {
        this.form = form;
        if (!form) {
            console.error("Form element is not provided");
            return;
        }

        this.inputs = this.form.querySelectorAll('input, textarea');
        /**
         * WARNING: This list includes some extensive 
         * and potentially offensive words. Please open list with caution.
         * */
        // 
        this.profanityList = [];

        this.init();
    }

    // initialise form 
    async init() {
        "use strict";
        if (!this.form) {
            return;
        }

        addEventListenerCompat(this.form, 'submit', (event) => {
            this.validateForm(event);
        });
        this.inputs.forEach(input => {
            const label = document.querySelector(`label[for="${input.id}"]`);
            if (label) {
                addEventListenerCompat(label, 'mouseover', () => this.showToolTip(input, null));
                addEventListenerCompat(label, 'mouseout', () => this.hideToolTip(input));
            }
            
            addEventListenerCompat(input, 'focus', () => this.hideToolTip(input));
            addEventListenerCompat(input, 'blur', () => this.validateField(input));
        });

        this.setSelectPickerFromURL();
        this.showContactForm();
        this.profanityList = await this.loadProfanityList();
        this.Popup = new Popup();
    }
    
    async loadProfanityList() {
        try {
            const response = await fetch('../profanityList.txt');
            if (!response.ok) throw new Error('Failed to load profanity list');
            
            const data = await response.text();
            // Normalize line endings and filter out empty lines
            return data.split(/\r?\n/).map(word => word.trim()).filter(word => word.length > 0);
        } catch (err) {
            console.error('Error loading profanity list:', err);
            return [];
        }
    }
    
    setSelectPickerFromURL() {
        "use strict";
        // Get URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const packageParam = decodeURIComponent(urlParams.get('package')); // Decode URL parameter
    
        // Mapping of URL parameters to select option values
        const packageMap = {
            "BasicPackage": "basic",
            "StandardPackage": "standard",
            "PremiumPackage": "premium",
            "CorporatePackage": "enquire"
        };
        
        const mappedValue = packageMap[packageParam];
    
        if (mappedValue) {
            const selectElement = this.form.querySelector('select[name="package"]');
            if (!selectElement) {
                return;
            }
    
            const optionToSelect = selectElement.querySelector(`option[value="${mappedValue}"]`);
    
            if (optionToSelect) {
                selectElement.value = mappedValue;
            } else {
                return;
            }
        } else {
            return;
        }
    }

    validateForm(event) {
        "use strict";
        event.preventDefault();
        
        console.log("Form submission detected for:", this.form);
        let isValid = true;
        this.inputs.forEach(input => {
            if (!this.validateField(input)) {
                isValid = false;
            }
        });
        
        if (!isValid) {
            return;
        }
        
        if (this.onSubmit && typeof this.onSubmit === 'function') {
            this.onSubmit(this.form); 
        }
    }

    setOnSubmit(callback) {
        this.onSubmit = callback; 
    }
    
    // Timer for if verified within limit for security
    startVerificationTimeout() {
        this.timeoutId = setTimeout(() => {
            this.Popup.close();
            this.Popup.show('Verification code entry timed out. Please request a new code.');
            this.resetVerificationProcess();
        }, 1 * 60 * 1000); // 1 minute in milliseconds
    }
    
    resetVerificationProcess() {
        clearTimeout(this.timeoutId);
        this.form.reset();
        this.showContactForm();
    }
    
    // Method to show the verification form
    showVerificationForm() {
    "use strict";
        const contactForm = document.querySelector('.contact-form');
        const verificationForm = document.querySelector('.verification-form');
        if (contactForm && verificationForm) {
            contactForm.style.display = 'none';
            verificationForm.style.display = 'block';
        }
    }
    
    // Method to show the contact form
    showContactForm() {
    "use strict";
        const contactForm = document.querySelector('.contact-form');
        const verificationForm = document.querySelector('.verification-form');
        if (contactForm && verificationForm) {
            verificationForm.style.display = 'none';
            contactForm.style.display = 'block';
        }
    }
    
    reset() {
        "use strict";
        // clear / empty all of the fields
        this.inputs.forEach(input => {
            input.value = '';
        });

        // Clear error messages
        this.form.querySelectorAll('.error-message').forEach(errMessage => {
            errMessage.textContent = '';
            errMessage.style.display = 'none';
        });

        // Hide tooltips
        this.form.querySelectorAll('.tooltip').forEach(tooltip => {
            tooltip.style.display = 'none';
        });

        // Remove errors
        this.form.querySelectorAll('.has-error').forEach(formGroup => {
            formGroup.classList.remove('has-error');
        });
    }

    validateField(input) {
        const formGroup = input.parentElement;
        const errMessage = input.parentElement.querySelector('.error-message');
    
        let isValid = true;
    
        if (input.type === 'hidden') {
            return true; // Skip validation for hidden fields
        }

        // Check for profanity in a field
        if (this.containsProfanity(input.value)) {
            this.showError(errMessage, formGroup, 'Please avoid inappropriate language');
            isValid = false;
        }
        // is the field a valid email
        else if (input.type === 'email' && !this.isValidEmail(input.value)) {
            this.showError(errMessage, formGroup, 'Please enter a valid email');
            isValid = false;
        }
        // is the field a valid phone
        else if (input.type === 'tel' && !this.isValidPhone(input.value)) {
            this.showError(errMessage, formGroup, 'Please enter a valid phone number');
            isValid = false;
        }
        // is the field empty
        else if (input.value.trim() === '') {
            this.showError(errMessage, formGroup, 'This field is required.');
            isValid = false;
        } else {
            this.clearError(errMessage, formGroup);
            this.hideToolTip(input);
        }

        return isValid;
    }
    
    showError(errMessage, formGroup, message) {
        "use strict";
        if (!errMessage) {
            return;
        }

        errMessage.textContent = message;
        errMessage.style.display = 'block';
        formGroup.classList.add('has-error');

        const inputHeight = formGroup.querySelector('input, textarea').offsetHeight;
        errMessage.style.top = `${inputHeight + 25}px`;
    }

    clearError(errMessage, formGroup) {
        "use strict";
        if (!errMessage) {
            return;
        }

        errMessage.style.display = 'none';
        formGroup.classList.remove('has-error');
    }

    isValidEmail(email) {
        "use strict";
        // regex (regular expression) for emails
        // Credit to: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
        const re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return re.test(email);
    }
    
    isValidPhone(phone) {
        "use strict";
        // regex (regular expression) for phone numbers
        // Credit to https://stackoverflow.com/users/7794769/stomy for regex phone numbers
        // Overflow page: https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
        const re = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        return re.test(phone);
    }

    containsProfanity(text) {
        "use strict";
        // filter text through list to check if it contains profanity
        const lowerText = text.toLowerCase();
        return this.profanityList.some(word => lowerText.includes(word));
    }
    
    showToolTip(input, msg) {
        "use strict";
        let tooltip = input.parentElement.querySelector('.tooltip');
        // create tooltip if it does not exist
        if (!tooltip) {
            tooltip = document.createElement('aside');
            tooltip.classList.add('tooltip');
            input.parentElement.appendChild(tooltip);
        }

        if (msg) {
            tooltip.textContent = msg;
        }

        tooltip.style.display = 'block';
    }

    hideToolTip(input) {
        "use strict";
        const tooltip = input.parentElement.querySelector('.tooltip');
        // if tooltip isn't null
        if (!tooltip) {
            return;
        }

        tooltip.style.display = 'none';
    }
}
// end of formvalidator class

/**
* Chart class for drawing various types of charts (line, bar) on an HTML canvas.
* 
* @param {CanvasRenderingContext2D} ctx - The 2D rendering context for the canvas.
* @param {string} type - The type of chart ('line' or 'bar').
* @param {Object} data - The data for the chart, containing labels and datasets.
* @param {Object} options - Chart configuration options like colors and interactivity.
*/
class Chart {
    constructor(ctx, type, data, options) {
        this.ctx = ctx;
        this.canvas = ctx.canvas;
        this.type = type;
        this.data = data;
        this.options = options;
        this.tooltip = null;
        this.createTooltip();
        this.canvas.addEventListener('mousemove', this.onMouseMove.bind(this));
        this.draw();
    }

    createTooltip() {
        this.tooltip = document.createElement('div');
        this.tooltip.style.position = 'absolute';
        this.tooltip.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
        this.tooltip.style.color = 'white';
        this.tooltip.style.padding = '5px';
        this.tooltip.style.borderRadius = '5px';
        this.tooltip.style.pointerEvents = 'none';
        this.tooltip.style.fontSize = '12px';
        this.tooltip.style.display = 'none';
        document.body.appendChild(this.tooltip);
    }

    onMouseMove(event) {
        const rect = this.canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const hoveredElement = this.getHoveredElement(x, y);
        
        if (hoveredElement) {
            this.tooltip.innerHTML = `Value: ${hoveredElement.value}`;  // Now this should show the correct value
            this.tooltip.style.display = 'block';
            this.tooltip.style.left = `${event.clientX + 10}px`;
            this.tooltip.style.top = `${event.clientY - 20}px`;
            this.highlightElement(hoveredElement);
        } else {
            this.tooltip.style.display = 'none';
            this.draw(); // Redraw to remove highlights
        }
    }

    getHoveredElement(mouseX, mouseY) {
        const { data, type } = this;
        const ctx = this.ctx;
        const xScale = ctx.canvas.width / data.labels.length;
        const yScale = ctx.canvas.height / Math.max(...data.datasets[0].data);

        if (type === 'line') {
            return data.datasets[0].data.map((value, index) => {
                const x = index * xScale;
                const y = ctx.canvas.height - value * yScale;
                const distance = Math.sqrt((mouseX - x) ** 2 + (mouseY - y) ** 2);
                if (distance < 10) {  // Hover tolerance for line chart points
                    return { value, index };
                }
            }).find(item => item !== undefined); // Return the hovered point (if any)
        } else if (type === 'bar') {
            const barWidth = ctx.canvas.width / data.labels.length;
            return data.datasets[0].data.map((value, index) => {
                const x = index * barWidth;
                const y = ctx.canvas.height - value * yScale;
                if (mouseX > x && mouseX < x + barWidth - 5 && mouseY > y && mouseY < ctx.canvas.height) {
                    return { value, index };
                }
            }).find(item => item !== undefined); // Return the hovered bar (if any)
        }
        return null;
    }

    highlightElement(element) {
        const { data, type } = this;
        const ctx = this.ctx;
        const xScale = ctx.canvas.width / data.labels.length;
        const yScale = ctx.canvas.height / Math.max(...data.datasets[0].data);

        this.draw(); // Redraw everything first

        if (type === 'line') {
            data.datasets[0].data.forEach((value, index) => {
                if (index === element.index) {
                    ctx.beginPath();
                    ctx.arc(index * xScale, ctx.canvas.height - value * yScale, 10, 0, Math.PI * 2);
                    ctx.fillStyle = 'red';
                    ctx.fill();
                }
            });
        } else if (type === 'bar') {
            const barWidth = ctx.canvas.width / data.labels.length;
            data.datasets[0].data.forEach((value, index) => {
                if (index === element.index) {
                    ctx.fillStyle = 'red';
                    ctx.fillRect(index * barWidth, ctx.canvas.height - value * yScale, barWidth - 5, value * yScale);
                }
            });
        }
    }

    draw() {
        this.clearCanvas();

        // Only draw the grid and axes for line and bar charts
        if (this.type === 'line' || this.type === 'bar') {
            this.drawGrid();
            this.drawAxes();
        }

        this.drawChart();
    }

    clearCanvas() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

    drawGrid() {
        const { ctx } = this;
        const gridColor = this.options.gridColor || '#e0e0e0';

        ctx.strokeStyle = gridColor;
        ctx.lineWidth = 1;

        // Draw vertical lines
        for (let x = 0; x <= ctx.canvas.width; x += 50) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, ctx.canvas.height);
            ctx.stroke();
        }

        // Draw horizontal lines
        for (let y = 0; y <= ctx.canvas.height; y += 50) {
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(ctx.canvas.width, y);
            ctx.stroke();
        }
    }

    drawAxes() {
        const ctx = this.ctx;
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;

        // Draw X axis
        ctx.beginPath();
        ctx.moveTo(0, ctx.canvas.height);
        ctx.lineTo(ctx.canvas.width, ctx.canvas.height);
        ctx.stroke();

        // Draw Y axis
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(0, ctx.canvas.height);
        ctx.stroke();
    }

    drawChart() {
        const ctx = this.ctx;
        const data = this.data;
        const xScale = ctx.canvas.width / data.labels.length;
        const yScale = ctx.canvas.height / Math.max(...data.datasets[0].data);

        if (this.type === 'line') {
            this.drawLineChart(xScale, yScale);
        } else if (this.type === 'bar') {
            this.drawBarChart(xScale, yScale);
        } else if (this.type === 'pie') {
            this.drawPieChart();
        }
    }

    drawLineChart(xScale, yScale) {
        const ctx = this.ctx;
        const data = this.data;
        ctx.strokeStyle = this.options.borderColor || 'blue';
        ctx.lineWidth = 2;

        ctx.beginPath();
        ctx.moveTo(0, ctx.canvas.height - data.datasets[0].data[0] * yScale);
        data.datasets[0].data.forEach((value, index) => {
            ctx.lineTo(index * xScale, ctx.canvas.height - value * yScale);
        });
        ctx.stroke();

        // Draw points
        data.datasets[0].data.forEach((value, index) => {
            ctx.beginPath();
            ctx.arc(index * xScale, ctx.canvas.height - value * yScale, 5, 0, Math.PI * 2);
            ctx.fillStyle = this.options.pointColor || 'blue';
            ctx.fill();
        });
    }

    drawBarChart(xScale, yScale) {
        const ctx = this.ctx;
        const data = this.data;
        const barWidth = ctx.canvas.width / data.labels.length;

        data.datasets[0].data.forEach((value, index) => {
            ctx.fillStyle = this.options.backgroundColor || 'blue';
            ctx.fillRect(index * barWidth, ctx.canvas.height - value * yScale, barWidth - 5, value * yScale);
        });
    }

    drawPieChart() {
        const { data } = this;
        const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0);
        let startAngle = 0;

        data.datasets[0].data.forEach((value, index) => {
            const sliceAngle = (value / total) * 2 * Math.PI;

            // Draw slice
            this.ctx.beginPath();
            this.ctx.moveTo(this.canvas.width / 2, this.canvas.height / 2);
            this.ctx.arc(this.canvas.width / 2, this.canvas.height / 2, Math.min(this.canvas.width, this.canvas.height) / 2, startAngle, startAngle + sliceAngle);
            this.ctx.closePath();
            
            // Set color
            this.ctx.fillStyle = this.options.colors[index % this.options.colors.length];
            this.ctx.fill();

            // Update the starting angle for the next slice
            startAngle += sliceAngle;
        });
    }
}

class KeyboardNavigation {
    constructor() {
        this.elements = new Map(); 
    }

    /**
     * Add keyboard navigation to a specific element.
     * @param {HTMLElement} element - The target element to make keyboard navigable.
     * @param {Function} callback - The function to call when the element is activated via keyboard.
     */
    addKeyboardSupport(element, callback) {
        if (!element) return;

        // Ensure the element is focusable
        element.setAttribute('tabindex', '0');

        const keyHandler = (event) => {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault(); // Prevent default scrolling for Space key
                callback(event);
            }
        };

        addEventListenerCompat(element, 'keydown', keyHandler);
        this.elements.set(element, keyHandler);
    }
    
    /**
     * Remove keyboard navigation from a specific element.
     * @param {HTMLElement} element - The target element to remove keyboard navigation from.
     */
    removeKeyboardSupport(element) {
        if (!this.elements.has(element)) return;

        // Retrieve and remove the associated handler
        const keyHandler = this.elements.get(element);
        removeEventListenerCompat(element, 'keydown', keyHandler);

        // Clean up attributes
        element.removeAttribute('tabindex');

        // Remove element from the Map
        this.elements.delete(element);
    }

    /**
     * Remove keyboard navigation from all registered elements.
     */
    clearAll() {
        this.elements.forEach((_handler, element) => {
            this.removeKeyboardSupport(element);
        });
    }
}

export default {
    addEventListenerCompat,
    removeEventListenerCompat,
    Popup,
    FormValidator,
    Chart,
    KeyboardNavigation
};